import { Routes, Route } from "react-router-dom";
import React from "react";
import TreeDisplayPage from "../pages/TreeDisplayPage";

const AppNavigator = () => {
  return (
    <Routes>
      <Route path="*" element={<TreeDisplayPage />} />
    </Routes>
  );
};

export default AppNavigator;

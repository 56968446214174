import React from "react";
import Tree from "../../components/Tree";

function TreeDisplayPage() {
  return (
    <>
      <Tree />
    </>
  );
}

export default TreeDisplayPage;
